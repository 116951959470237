import { ref } from 'vue';

interface RecaptchaOptions {
    proceedFunction?: (response: string) => Promise<void> | null;
}

export function useRecaptcha(options: RecaptchaOptions, customButtonId: string) {
    const { proceedFunction } = options;
    const recaptchaWidgetId = ref(null);
    const recaptchaResponse = ref('');
    const siteKey = import.meta.env.VITE_INVISIBLE_RECAPTCHA_KEY;

    const resetRecaptcha = async (): Promise<void> => {
        await window.grecaptcha.reset(recaptchaWidgetId.value);
    };

    const verifyCallback = async (response: string): Promise<void> => {
        recaptchaResponse.value = response;
        if (proceedFunction) {
            await proceedFunction(recaptchaResponse.value);
            setTimeout(() => {
                resetRecaptcha();
            }, 1000);
        }
    };

    const initRecaptcha = async (): Promise<void> => {
        if (window.grecaptcha && siteKey) {
            recaptchaWidgetId.value = window.grecaptcha.render(customButtonId, {
                sitekey: siteKey,
                callback: verifyCallback,
                size: 'invisible',
            });
        }
    };
    const executeRecaptcha = async (): Promise<void> => {
        try {
            recaptchaResponse.value = await window.grecaptcha.execute(recaptchaWidgetId.value);
        } catch (error) {
            throw new Error(`Issue encountered during reCAPTCHA execution: ${error.message || error}`);
        }
    };

    return {
        recaptchaResponse, siteKey, initRecaptcha, executeRecaptcha, resetRecaptcha,
    };
}
