export const loadRecaptcha = () => new Promise((resolve) => {
    if (window.grecaptcha) {
        resolve();

        return;
    }

    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoaded&render=explicit';
    script.async = true;
    script.defer = true;

    window.onRecaptchaLoaded = function onRecaptchaLoaded() {
        resolve();
    };

    document.head.appendChild(script);
});
